* { -webkit-font-smoothing: antialiased; }

body {
  padding-bottom: 48px;
}

.full {
  max-width: 960px;
  margin: 0 auto;
  padding: 0 24px;
}

header {
  padding: 24px 0;
  font-size: 2vw;
}

.row {
  display: flex;
}

.record {
  width: 25%;
}

@media (max-width: 600px) {
  header {
    font-size: 4vw;
  }

  .row {
    flex-wrap: wrap;
  }

  .record {
    width: 50%;
  }
}

.record img {
  max-width: 100%;
}

.record a {
  display: block;
  margin-bottom: -3px;
}
